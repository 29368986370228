body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

div {
  margin: 0;
  padding: 0;
}

.logo {
  width: 100%;
  text-align: center;
  padding-top: 14px;
  padding-bottom: 14px;
}

* {
  box-sizing: border-box;
}

h1,
h2,
p {
  padding: 0 5%;
}

#ta-logo {
  margin: 16px;
}

.video-select select {
  width: 360px;
  border-radius: 2px;
  background-color: #2e2e2e;
  color: rgba(255, 255, 255, 0.87);
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  padding: 6px;
}

select > option {
  height: 20px;
  width: 78px;
  color: rgba(255, 255, 255, 0.87);
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  background-color: #2e2e2e;
}

.orange {
  color: #ef8116;
}

@media only screen and (max-width: 480px) {
  #ta-logo {
    margin: 0;
    padding: 0;
  }

  .video-select select {
    width: 100px;
    padding: 0px;
  }

  select > option {
    height: 20px;
    width: 78px;
  }
}

/* @keyframes example {
  0% {
    bottom: 0;
    height: 0;
  }
  5% {
    bottom: 44px;
    height: 44px;
  }
  10% {
    bottom: 88px;
    height: 88px;
  }
  90% {
    bottom: 88px;
    height: auto;
  }
  95% {
    bottom: 44px;
    height: auto;
  }
  100% {
    bottom: 0;
    height: 0;
  }
} */
/* .example-enter {
  height: 0px;
}

.example-enter.example-enter-active {
  height: 100px;
  -webkit-transition: height 3s ease;
}

.example-leave.example-leave-active {
  height: 0px;
  -webkit-transition: height 3s ease;
} */

div.banner-img {
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

div.banner-img img {
  width: 100%;
}

/* div.banner-img.moved {
  bottom: 88px;
} */
